import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../img/Logo-BluStay-02.png";
import useCurrency from "../hooks/useCurrency";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";

import { currencyPage } from "../redux/reducers/InformacionReducer";

const TITLES = [
    {
        id: 2,
        title: "Funcionalidades",
        url: "funcionalidades",
    },
    {
        id: 3,
        title: "Planes",
        url: "planes",
    },
    {
        id: 4,
        title: "Multicasas",
        url: "multicasas",
    },
    {
        id: 5,
        title: "Testimonios",
        url: "testimonios",
    },
    {
        id: 6,
        title: "Nosotros",
        url: "nosotros",
    },
    {
        id: 7,
        title: "Contáctanos",
        url: "contactanos",
    },
    {
        id: 8,
        title: "Simulador",
        url: "calculadora",
    },
    {
        id: 9,
        title: "Políticas de uso y privacidad",
        url: "politicas-de-uso-y-privacidad",
    },
];

interface Pros {
    calculadora?: string;
}

export const Footer = ({ calculadora }: Pros) => {
    const [titleSelected, setTitleSelected] = useState<number>(0);
    const { currency } = useAppSelector((state) => state.informacion);

    const navigate = useNavigate();
    const [selectedCurrency, setSelectedCurrency] = useState<string>("");
    const dispatch = useAppDispatch();
    const { view, tags } = useParams();

    const handleSmoothScroll = (title: string) => {
        navigate(`/home/${tags ? tags : "6"}/${title}`);
    };

    const handleChangeCurrency = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const selectedValue = event.target.value;
        setSelectedCurrency(selectedValue);
        localStorage.setItem("currency", selectedValue);
        dispatch(currencyPage(selectedValue));
    };

    useEffect(() => {
        setSelectedCurrency(currency);
    }, [currency]);

    return (
        <div className={`flex flex-col  text-white  bg-[#179BD8] `}>
            <a
                className="z-50"
                href={`https://wa.me/51950707924?text=`}
                target="_blank"
                rel="noreferrer">
                <div className="fixed select-none bottom-5 right-5 xs:right-10 bg-[#00BB22] shadow-lg rounded-full p-3 flex justify-center items-center hover:scale-110 transition animate-bounce z-30">
                    <svg
                        className="w-12 h-12 "
                        id="Capa_2"
                        data-name="Capa 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 99.76 100">
                        <g id="Testimonios">
                            <g>
                                <path
                                    fill="white"
                                    d="m0,100l7.17-26.75c-4.06-7.32-6.2-15.53-6.2-23.86C.97,22.16,23.13,0,50.36,0s49.39,22.16,49.39,49.39-22.16,49.39-49.39,49.39c-8.18,0-16.25-2.06-23.48-5.98L0,100Zm28.22-17.23l1.69,1.01c6.22,3.7,13.29,5.66,20.45,5.66,22.09,0,40.06-17.97,40.06-40.06S72.45,9.34,50.36,9.34,10.31,27.3,10.31,49.39c0,7.29,2.03,14.47,5.86,20.77l1.04,1.71-4,14.93,15.02-4.02Z"
                                />
                                <path
                                    fill="white"
                                    d="m68.17,56.26c-2.03-1.22-4.68-2.57-7.07-1.59-1.84.75-3.01,3.63-4.2,5.1-.61.75-1.34.87-2.28.49-6.9-2.75-12.18-7.35-15.99-13.7-.64-.99-.53-1.76.25-2.68,1.15-1.35,2.59-2.89,2.9-4.72.31-1.82-.54-3.96-1.3-5.58-.96-2.08-2.04-5.04-4.12-6.21-1.91-1.08-4.43-.47-6.13.91-2.94,2.39-4.36,6.14-4.31,9.86.01,1.06.14,2.11.39,3.13.59,2.45,1.73,4.74,3,6.92.96,1.64,2.01,3.23,3.13,4.77,3.69,5.01,8.28,9.37,13.59,12.62,2.66,1.63,5.52,3.05,8.48,4.03,3.32,1.1,6.28,2.24,9.87,1.56,3.75-.71,7.46-3.04,8.95-6.66.44-1.07.66-2.27.42-3.4-.51-2.34-3.68-3.73-5.57-4.86Z"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
            </a>

            <div className="flex items-center justify-between w-full p-5 py-5 text-white lg:py-10">
                <div className="flex flex-col items-start justify-between w-11/12 mx-auto lg:flex-row md:w-5/6 xl:w-4/5">
                    <div className="flex gap-3 lg:hidden lg:text-lg">
                        <div className="mb-3 font-bold">Síguenos</div>

                        <div>
                            <a
                                href="https://www.facebook.com/bluustay.io"
                                target="_blank">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M31.1667 16.9999C31.1667 9.17992 24.82 2.83325 17 2.83325C9.18004 2.83325 2.83337 9.17992 2.83337 16.9999C2.83337 23.8566 7.70671 29.5657 14.1667 30.8832V21.2499H11.3334V16.9999H14.1667V13.4583C14.1667 10.7241 16.3909 8.49992 19.125 8.49992H22.6667V12.7499H19.8334C19.0542 12.7499 18.4167 13.3874 18.4167 14.1666V16.9999H22.6667V21.2499H18.4167V31.0957C25.5709 30.3874 31.1667 24.3524 31.1667 16.9999Z"
                                        fill="#C0D345"
                                    />
                                </svg>
                            </a>
                        </div>

                        <div>
                            <a
                                href="https://www.instagram.com/blustay.io/"
                                target="_blank">
                                <svg
                                    width="35"
                                    height="34"
                                    viewBox="0 0 35 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.375 2.83325H23.625C28.2916 2.83325 32.0833 6.51659 32.0833 11.0499V22.9499C32.0833 25.1291 31.1921 27.2191 29.6059 28.76C28.0197 30.3009 25.8682 31.1666 23.625 31.1666H11.375C6.70829 31.1666 2.91663 27.4833 2.91663 22.9499V11.0499C2.91663 8.87072 3.80777 6.78078 5.39401 5.23986C6.98026 3.69893 9.13167 2.83325 11.375 2.83325ZM11.0833 5.66659C9.69091 5.66659 8.35555 6.20391 7.37098 7.16034C6.38642 8.11678 5.83329 9.41398 5.83329 10.7666V23.2333C5.83329 26.0524 8.18121 28.3333 11.0833 28.3333H23.9166C25.309 28.3333 26.6444 27.7959 27.6289 26.8395C28.6135 25.8831 29.1666 24.5859 29.1666 23.2333V10.7666C29.1666 7.94742 26.8187 5.66659 23.9166 5.66659H11.0833ZM25.1562 7.79158C25.6397 7.79158 26.1033 7.97815 26.4452 8.31025C26.7871 8.64235 26.9791 9.09276 26.9791 9.56242C26.9791 10.0321 26.7871 10.4825 26.4452 10.8146C26.1033 11.1467 25.6397 11.3333 25.1562 11.3333C24.6727 11.3333 24.2091 11.1467 23.8672 10.8146C23.5253 10.4825 23.3333 10.0321 23.3333 9.56242C23.3333 9.09276 23.5253 8.64235 23.8672 8.31025C24.2091 7.97815 24.6727 7.79158 25.1562 7.79158ZM17.5 9.91658C19.4338 9.91658 21.2885 10.6629 22.6559 11.9912C24.0234 13.3196 24.7916 15.1213 24.7916 16.9999C24.7916 18.8785 24.0234 20.6802 22.6559 22.0086C21.2885 23.337 19.4338 24.0833 17.5 24.0833C15.5661 24.0833 13.7114 23.337 12.344 22.0086C10.9765 20.6802 10.2083 18.8785 10.2083 16.9999C10.2083 15.1213 10.9765 13.3196 12.344 11.9912C13.7114 10.6629 15.5661 9.91658 17.5 9.91658ZM17.5 12.7499C16.3396 12.7499 15.2268 13.1977 14.4064 13.9947C13.5859 14.7917 13.125 15.8727 13.125 16.9999C13.125 18.1271 13.5859 19.2081 14.4064 20.0051C15.2268 20.8022 16.3396 21.2499 17.5 21.2499C18.6603 21.2499 19.7731 20.8022 20.5936 20.0051C21.414 19.2081 21.875 18.1271 21.875 16.9999C21.875 15.8727 21.414 14.7917 20.5936 13.9947C19.7731 13.1977 18.6603 12.7499 17.5 12.7499Z"
                                        fill="#C0D345"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://www.linkedin.com/company/blustay-io/"
                                target="_blank">
                                <svg
                                    width="33"
                                    height="34"
                                    viewBox="0 0 33 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M26.125 4.25C26.8543 4.25 27.5538 4.54851 28.0695 5.07986C28.5853 5.61122 28.875 6.33189 28.875 7.08333V26.9167C28.875 27.6681 28.5853 28.3888 28.0695 28.9201C27.5538 29.4515 26.8543 29.75 26.125 29.75H6.875C6.14565 29.75 5.44618 29.4515 4.93046 28.9201C4.41473 28.3888 4.125 27.6681 4.125 26.9167V7.08333C4.125 6.33189 4.41473 5.61122 4.93046 5.07986C5.44618 4.54851 6.14565 4.25 6.875 4.25H26.125ZM25.4375 26.2083V18.7C25.4375 17.4751 24.9652 16.3005 24.1246 15.4343C23.284 14.5682 22.1438 14.0817 20.955 14.0817C19.7863 14.0817 18.425 14.8183 17.765 15.9233V14.3508H13.9288V26.2083H17.765V19.2242C17.765 18.1333 18.6175 17.2408 19.6763 17.2408C20.1868 17.2408 20.6764 17.4498 21.0374 17.8217C21.3984 18.1937 21.6012 18.6982 21.6012 19.2242V26.2083H25.4375ZM9.46 12.1267C10.0727 12.1267 10.6602 11.8759 11.0934 11.4296C11.5266 10.9832 11.77 10.3779 11.77 9.74667C11.77 8.42917 10.7387 7.3525 9.46 7.3525C8.8437 7.3525 8.25265 7.60474 7.81686 8.05374C7.38107 8.50273 7.13625 9.11169 7.13625 9.74667C7.13625 11.0642 8.18125 12.1267 9.46 12.1267ZM11.3713 26.2083V14.3508H7.5625V26.2083H11.3713Z"
                                        fill="#C0D345"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <span
                        onClick={() => handleSmoothScroll("inicio")}
                        className="inline-block mb-5 font-medium cursor-pointer lg:mr-8 lg:text-lg">
                        <img src={logo} className=" w-44" alt="logo blustay" />
                    </span>
                    <div className="flex flex-col gap-3 mb-10 text-left lg:text-lg">
                        <div className="font-bold lg:mb-3 ">Enlaces</div>
                        {TITLES.map((title) => (
                            <div
                                key={title.id}
                                onClick={() => handleSmoothScroll(title.url)}
                                onMouseEnter={() => setTitleSelected(title.id)}
                                onMouseLeave={() => setTitleSelected(0)}
                                className={`relative  capitalize cursor-pointer  select-none  ${" text-white "}`}>
                                <p className={``}>{title.title}</p>
                                <span
                                    className={`${
                                        titleSelected === title.id
                                            ? "w-full"
                                            : "w-0"
                                    } absolute top-6 left-0 bg-white h-0.5 transition-all ease-in-out duration-500`}></span>
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-col gap-3 mb-5 text-left lg:text-lg">
                        <div className="font-bold lg:mb-3">Contáctanos</div>

                        <div className="relative text-white underline capitalize cursor-pointer select-none">
                            <a
                                href="https://wa.me/51950707924"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-white">
                                +51 950 707 924
                            </a>
                        </div>

                        <div className="relative text-white underline cursor-pointer select-none">
                            <a
                                href="mailto:katherine@bluecorner.com.pe"
                                className="text-white">
                                katherine@bluecorner.com.pe
                            </a>
                        </div>
                    </div>
                    <div className="hidden gap-3 lg:flex lg:text-lg">
                        <div className="mb-3 font-bold">Síguenos</div>

                        <div>
                            <a
                                href="https://www.facebook.com/bluustay.io"
                                target="_blank">
                                <svg
                                    width="34"
                                    height="34"
                                    viewBox="0 0 34 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M31.1667 16.9999C31.1667 9.17992 24.82 2.83325 17 2.83325C9.18004 2.83325 2.83337 9.17992 2.83337 16.9999C2.83337 23.8566 7.70671 29.5657 14.1667 30.8832V21.2499H11.3334V16.9999H14.1667V13.4583C14.1667 10.7241 16.3909 8.49992 19.125 8.49992H22.6667V12.7499H19.8334C19.0542 12.7499 18.4167 13.3874 18.4167 14.1666V16.9999H22.6667V21.2499H18.4167V31.0957C25.5709 30.3874 31.1667 24.3524 31.1667 16.9999Z"
                                        fill="#C0D345"
                                    />
                                </svg>
                            </a>
                        </div>

                        <div>
                            <a
                                href="https://www.instagram.com/blustay.io/"
                                target="_blank">
                                <svg
                                    width="35"
                                    height="34"
                                    viewBox="0 0 35 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.375 2.83325H23.625C28.2916 2.83325 32.0833 6.51659 32.0833 11.0499V22.9499C32.0833 25.1291 31.1921 27.2191 29.6059 28.76C28.0197 30.3009 25.8682 31.1666 23.625 31.1666H11.375C6.70829 31.1666 2.91663 27.4833 2.91663 22.9499V11.0499C2.91663 8.87072 3.80777 6.78078 5.39401 5.23986C6.98026 3.69893 9.13167 2.83325 11.375 2.83325ZM11.0833 5.66659C9.69091 5.66659 8.35555 6.20391 7.37098 7.16034C6.38642 8.11678 5.83329 9.41398 5.83329 10.7666V23.2333C5.83329 26.0524 8.18121 28.3333 11.0833 28.3333H23.9166C25.309 28.3333 26.6444 27.7959 27.6289 26.8395C28.6135 25.8831 29.1666 24.5859 29.1666 23.2333V10.7666C29.1666 7.94742 26.8187 5.66659 23.9166 5.66659H11.0833ZM25.1562 7.79158C25.6397 7.79158 26.1033 7.97815 26.4452 8.31025C26.7871 8.64235 26.9791 9.09276 26.9791 9.56242C26.9791 10.0321 26.7871 10.4825 26.4452 10.8146C26.1033 11.1467 25.6397 11.3333 25.1562 11.3333C24.6727 11.3333 24.2091 11.1467 23.8672 10.8146C23.5253 10.4825 23.3333 10.0321 23.3333 9.56242C23.3333 9.09276 23.5253 8.64235 23.8672 8.31025C24.2091 7.97815 24.6727 7.79158 25.1562 7.79158ZM17.5 9.91658C19.4338 9.91658 21.2885 10.6629 22.6559 11.9912C24.0234 13.3196 24.7916 15.1213 24.7916 16.9999C24.7916 18.8785 24.0234 20.6802 22.6559 22.0086C21.2885 23.337 19.4338 24.0833 17.5 24.0833C15.5661 24.0833 13.7114 23.337 12.344 22.0086C10.9765 20.6802 10.2083 18.8785 10.2083 16.9999C10.2083 15.1213 10.9765 13.3196 12.344 11.9912C13.7114 10.6629 15.5661 9.91658 17.5 9.91658ZM17.5 12.7499C16.3396 12.7499 15.2268 13.1977 14.4064 13.9947C13.5859 14.7917 13.125 15.8727 13.125 16.9999C13.125 18.1271 13.5859 19.2081 14.4064 20.0051C15.2268 20.8022 16.3396 21.2499 17.5 21.2499C18.6603 21.2499 19.7731 20.8022 20.5936 20.0051C21.414 19.2081 21.875 18.1271 21.875 16.9999C21.875 15.8727 21.414 14.7917 20.5936 13.9947C19.7731 13.1977 18.6603 12.7499 17.5 12.7499Z"
                                        fill="#C0D345"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://www.linkedin.com/company/blustay-io/"
                                target="_blank">
                                <svg
                                    width="33"
                                    height="34"
                                    viewBox="0 0 33 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M26.125 4.25C26.8543 4.25 27.5538 4.54851 28.0695 5.07986C28.5853 5.61122 28.875 6.33189 28.875 7.08333V26.9167C28.875 27.6681 28.5853 28.3888 28.0695 28.9201C27.5538 29.4515 26.8543 29.75 26.125 29.75H6.875C6.14565 29.75 5.44618 29.4515 4.93046 28.9201C4.41473 28.3888 4.125 27.6681 4.125 26.9167V7.08333C4.125 6.33189 4.41473 5.61122 4.93046 5.07986C5.44618 4.54851 6.14565 4.25 6.875 4.25H26.125ZM25.4375 26.2083V18.7C25.4375 17.4751 24.9652 16.3005 24.1246 15.4343C23.284 14.5682 22.1438 14.0817 20.955 14.0817C19.7863 14.0817 18.425 14.8183 17.765 15.9233V14.3508H13.9288V26.2083H17.765V19.2242C17.765 18.1333 18.6175 17.2408 19.6763 17.2408C20.1868 17.2408 20.6764 17.4498 21.0374 17.8217C21.3984 18.1937 21.6012 18.6982 21.6012 19.2242V26.2083H25.4375ZM9.46 12.1267C10.0727 12.1267 10.6602 11.8759 11.0934 11.4296C11.5266 10.9832 11.77 10.3779 11.77 9.74667C11.77 8.42917 10.7387 7.3525 9.46 7.3525C8.8437 7.3525 8.25265 7.60474 7.81686 8.05374C7.38107 8.50273 7.13625 9.11169 7.13625 9.74667C7.13625 11.0642 8.18125 12.1267 9.46 12.1267ZM11.3713 26.2083V14.3508H7.5625V26.2083H11.3713Z"
                                        fill="#C0D345"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex  justify-center w-11/12 md:w-5/6 mx-auto gap-6 py-5 text-white border-t border-[#C0D345]">
                <div className="text-sm text-center sm:text-end md723:text-start sm:grid-cols-2 xs:text-base">
                    <div className="flex text-center">
                        <p>©2024 Blustay / Derechos Reservados</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
