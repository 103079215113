import React, { lazy, Suspense, memo } from "react";
import { Footer } from "../Footer";
import { Contacto } from "./Contacto";

import MainNavbar from "../MainNavbar/MainNavbar";
import Inicio from "./Inicio";
import Funcionalidades from "./Funcionalidades";
import Planes from "./Planes";
import Testimonios from "./Testimonios";
import Mision from "./Mision";
import Rendimiento from "./Rendimiento";
import MultiCasas from "./MultiCasas";

const MemoInicio = memo(Inicio);
const MemoFuncionalidades = memo(Funcionalidades);
const MemoPlanes = memo(Planes);
const MemoTestimonios = memo(Testimonios);
const MemoMision = memo(Mision);
const MemoRendimiento = memo(Rendimiento);
//dasdsa
const Home = () => {
    return (
        <div translate="no" className="overflow-hidden ">
            <MainNavbar />
            <div id="inicio">
                <Suspense fallback={<div>Loading...</div>}>
                    <MemoInicio />
                </Suspense>
            </div>
            <div id="funcionalidades">
                <MemoFuncionalidades />
            </div>
            <div id="planes">
                <MemoPlanes />
            </div>
            <div id="multicasas">
                <MultiCasas />
            </div>
            <div id="testimonios">
                <MemoTestimonios />
            </div>
            <div id="nosotros">
                <MemoMision />
            </div>
            <div id="contactanos">
                <Contacto />
            </div>
            {/* <div id="Rendimiento">
                <MemoRendimiento />
            </div> */}
            <Footer />
        </div>
    );
};

export default Home;
