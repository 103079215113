import React, { ReactNode, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { fetchAxiosToken } from "../../helpers/fetchAxiosToken";
import ResponsiveModalAbsolute from "../../components/ResponsiveModalAbsolute";
import { fetchAxiosNoToken } from "../../helpers/fetchAxiosNoToken";
import logo from "../../img/logo_modal.png";
interface Input {
    nombre: string;
    celular: string;
    correo: string;
    costoNoche: string;
    costoNocheMoneda: string;
    diasAlquiladas: string;
    cargado: boolean;
    etiqueta: string;
    apellido: string;
}

interface ModalProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    input: Input;
    setInput: (i: Input) => void;
}

export const ModalCalculadora = ({
    modalVisible,
    setModalVisible,
    input,
    setInput,
}: ModalProps) => {
    registerLocale("es", es);

    const [modalPromoCreada, setModalPromoCreada] = useState<boolean>(false);

    const [cupon, setCupon] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);

    const [correos, setCorreos] = useState<string[]>([]);
    const [permiso, setPermiso] = useState(false);
    const [permisoNovedades, setPermisoNovedades] = useState(false);
    useEffect(() => {
        setInput({
            ...input,
            cargado: false,
        });
    }, []);

    const handleSubmit = async () => {
        if (permiso === false) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes los terminos y condiciones",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        if (input.celular === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar tu numero de celular",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        if (input.celular.length < 7) {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar un numero de celular valido",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        if (input.nombre === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar tu nombre",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }
        if (input.correo === "") {
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: "Debes agregar tu correo",
                icon: "error",
                confirmButtonText: "Ok",
            });

            return;
        }

        try {
            setLoading(true);
            console.log(input);
            const data = await fetchAxiosNoToken({
                url: `/home/postSimulador`,
                body: input,
                method: "post",
            });

            setInput({
                ...input,
                cargado: true,
            });
            setModalVisible(false);

            setLoading(false);
        } catch (error: any) {
            console.log(error);
            Swal.fire({
                confirmButtonColor: "#029BFC",
                iconColor: "#029BFC",
                title: "Error",
                text: `${error.response.data.error}`,
                icon: "error",
            });
            setLoading(false);
        }
    };

    return (
        <>
            <ResponsiveModalAbsolute
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                closeStyle={true}
            >
                <div className="flex flex-col max-w-md gap-2 px-6 mx-auto ">
                    <img
                        src={logo}
                        className=" w-32  h-12 object-contain"
                        alt="logo"
                    />
                    <div className="text-3xl font-bold text-[#0E1928] ">
                        Completa los
                    </div>
                    <div className="text-3xl font-bold text-[#C0D345] ">
                        siguientes datos
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="w-full">
                            {/* <h2 className="text-base font-medium text-left">nombre</h2> */}
                            <div className="flex flex-col w-full mt-2">
                                <label className="pb-1 text-sm font-semibold text-[#0E1928] ">
                                    Nombres
                                </label>
                                <div className=" w-full  pl-[1px]  rounded-lg p-1.5 bg-transparent border border-[#C0D345]  outline-none focus:outline-none placeholder:text-gray-400 flex">
                                    <input
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                nombre: e.target.value,
                                            })
                                        }
                                        autoComplete="off"
                                        className="w-full px-3 rounded-lg outline-none placeholder:text-xs "
                                        placeholder=" Nombres"
                                        value={input.nombre}
                                        name="descuento_propietario"
                                        type="text"
                                    ></input>
                                </div>
                            </div>
                            <div className="flex flex-col w-full my-2">
                                <label className="pb-1 text-sm font-semibold text-[#0E1928] ">
                                    Correo
                                </label>
                                <div className=" w-full  pl-[1px]  rounded-lg p-1.5 bg-transparent border border-[#C0D345]  outline-none focus:outline-none placeholder:text-gray-400 flex">
                                    <input
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                correo: e.target.value,
                                            })
                                        }
                                        autoComplete="off"
                                        className="w-full px-3 rounded-lg outline-none placeholder:text-xs "
                                        placeholder=" Correo"
                                        value={input.correo}
                                        name="descuento_propietario"
                                        type="email"
                                    ></input>
                                </div>
                            </div>
                            <div className="flex flex-col w-full my-2">
                                <label className="pb-1 text-sm font-semibold text-[#0E1928] ">
                                    Celular
                                </label>
                                <div className=" w-full  pl-[1px]  rounded-lg p-1.5 bg-transparent border border-[#C0D345]  outline-none focus:outline-none placeholder:text-gray-400 flex">
                                    <input
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                celular: e.target.value,
                                            })
                                        }
                                        autoComplete="off"
                                        className="w-full px-3 rounded-lg outline-none placeholder:text-xs "
                                        placeholder=" Celular"
                                        value={input.celular}
                                        name="descuento_propietario"
                                        type="number"
                                    ></input>
                                </div>
                            </div>
                            <div className="flex justify-start items-center w-full gap-2 mt-5">
                                <div
                                    onClick={() => setPermiso(!permiso)}
                                    className={`border border-[#C0D345] w-6 h-6 rounded-md cursor-pointer ${
                                        permiso ? "bg-[#C0D345]" : ""
                                    }`}
                                ></div>
                                <p className="text-xs">
                                    Acepto los terminos y condiciones de Blustay{" "}
                                    <span className="">*</span>
                                </p>
                            </div>
                            <div className="flex justify-start items-center w-full gap-2 mt-5">
                                <div
                                    onClick={() =>
                                        setPermisoNovedades(!permisoNovedades)
                                    }
                                    className={`border border-[#C0D345] w-6 h-6 rounded-md cursor-pointer ${
                                        permisoNovedades ? "bg-[#C0D345]" : ""
                                    }`}
                                ></div>
                                <p className="text-xs">
                                    Acepto recibir otras comunicaciones de
                                    Blustay
                                </p>
                            </div>
                        </div>
                    </div>

                    <button
                        disabled={loading}
                        onClick={() => handleSubmit()}
                        className="px-3 py-2 mx-auto mt-4 w-2/3 border rounded-full font-semibold text-sm bg-[#C0D345] border-[#C0D345] active:translate-y-1 disabled:bg-gray-400 disabled:cursor-default"
                    >
                        {loading ? "Procesando.." : "Ver mis resultados"}
                    </button>
                </div>
            </ResponsiveModalAbsolute>
        </>
    );
};
